<template>
  <div
    style="
      background: #f7f5ee !important;
      padding-left: 10px;
      padding-right: 10px;
      height: 100vh;
    "
  >
    <h1 style="text-align: center; padding-top: 100px; margin: 0px; border: 0">
      {{ message }}
    </h1>
  </div>
</template>

<script>
import RentalAdministration from "@/services/api/rentalAdministration";

export default {
  name: "AuthSSO",
  methods: {
    runAuthentication() {
      RentalAdministration.authSSO(this.auth)
        .then((response) => {
          this.message = response.data.message;
          if (this.message === "OK") {
            localStorage.setItem("sso", JSON.stringify(this.auth));
            this.$router.push("/rental/offers");
          }
          this.offers = response.data;
        })
        .catch(() => {});
    },
  },

  mounted() {
    let q = this.$route.query;
    this.auth.username = q.username;
    this.auth.company = q.company;
    this.auth.hash = q.hash;
    this.runAuthentication();
  },

  data() {
    return {
      message: "Vänligen vänta...",
      auth: {
        username: "",
        company: "",
        hash: "",
      },
    };
  },
};
</script>
