<template>
  <main-layout>
    <div class="container">
      <div>
        <h2>{{ $t("newOffer") }}</h2>
      </div>
      <div class="form-group">
        <label for="project">{{ $t("project") }}</label>
        <input
          type="text"
          v-model="offer.project"
          class="form-control"
          id="project"
          :placeholder="$t('project')"
        />
      </div>

      <div class="form-group">
        <label for="comment">{{ $t("externalText") }}</label>
        <input
          type="text"
          v-model="offer.comment"
          class="form-control"
          id="comment"
          :placeholder="$t('externalText')"
        />
      </div>

      <div
        class="mb-4 mt-4"
        v-for="(line, index) in offer.lines"
        :key="`line-${index}`"
      >
        <div class="p-3" style="background: #e9e7e2 !important">
          <div class="row">
            <div class="col-3">
              <div class="form-group">
                <label for="comment">{{ $t("category") }}</label>
                <input
                  type="text"
                  min="0"
                  max="100"
                  v-model="line.category"
                  class="form-control"
                  id="comment"
                  :placeholder="$t('discount')"
                />
              </div>
              <div class="form-group mt-3">
                <label for="comment">{{ $t("subCategory") }}</label>
                <input
                  type="text"
                  min="0"
                  max="100"
                  v-model="line.subcategory"
                  class="form-control"
                  id="comment"
                  :placeholder="$t('discount')"
                />
              </div>
            </div>
            <div class="col-6">
              <div class="row">
                <div class="form-group">
                  <label for="comment">{{ $t("supplier") }}</label>
                  <v-select
                    v-model="line.levkod"
                    :options="suppliers"
                    label="leverantor"
                  ></v-select>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-4">
                  <div class="form-group">
                    <label for="comment">{{ $t("quantity") }}</label>
                    <input
                      type="number"
                      v-model="line.quantity"
                      class="form-control"
                      id="comment"
                      :placeholder="$t('quantity')"
                    />
                  </div>
                </div>
                <div class="col-4">
                  <div class="form-group">
                    <label>{{ $t("purchasePrice") }}</label>
                    <input
                      type="text"
                      v-model="line.price"
                      class="form-control"
                      :placeholder="$t('price')"
                    />
                  </div>
                </div>
                <div class="col-4">
                  <div class="form-group">
                    <label> {{ $t("purchaseDiscount") }}</label>
                    <input
                      type="number"
                      min="0"
                      max="100"
                      v-model="line.discount"
                      class="form-control"
                      :placeholder="$t('discount')"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-3">
              <div class="form-group">
                <label> {{ $t("text") }}</label>
                <input
                  type="text"
                  v-model="line.article"
                  class="form-control"
                  :placeholder="$t('text')"
                />
              </div>
              <div class="form-group mt-3">
                <label>{{ $t("articleNumber") }}</label>
                <input
                  type="text"
                  v-model="line.article"
                  class="form-control"
                  :placeholder="$t('text')"
                />
              </div>
            </div>
          </div>

          <div class="row mt-3 pt-3" style="border-top: 1px solid #d4d2cd">
            <div
              class="col"
              v-for="question in questions"
              :key="`line-${index}-${question.id}`"
            >
              <div class="form-group">
                <label for="project">{{ question.title }}</label>

                <div
                  class="form-check"
                  v-for="singleQuestion in question.lines"
                  :key="`line-${index}-${question.id}--${singleQuestion.id}`"
                >
                  <input
                    class="form-check-input"
                    type="radio"
                    :checked="question.id === singleQuestion.id"
                    :value="singleQuestion"
                    :name="`line-${index}-${question.id}`"
                    v-model="line.priceAdjust[question.id]"
                    :id="`line-${index}-${question.id}--${singleQuestion.id}`"
                  />
                  <label
                    class="form-check-label"
                    :for="`line-${index}-${question.id}--${singleQuestion.id}`"
                  >
                    {{ singleQuestion.sub_t }}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="p-3" style="background: #e2e0db !important">
          <h3 class="text-right">
            {{ $t("rentalPrice") }}:
            <span v-if="parseInt(line.price)">
              {{
                (parseInt(line.price) * parseInt(line.quantity)).toLocaleString(
                  "sv-SE",
                  {
                    style: "currency",
                    currency: "SEK",
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0,
                  }
                )
              }}</span
            >
          </h3>
        </div>
      </div>

      <div class="text-center">
        <button class="btn btn-dark" @click="addLineToOffer">
          {{ $t("addLine") }}:
        </button>
      </div>
      <hr />
      {{ flexParams }}
      <hr />
      {{ categories }}
    </div>
  </main-layout>
</template>

<script>
import RentalAdministration from "@/services/api/rentalAdministration";
import MainLayout from "@/layouts/MainLayout";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  name: "ViewFixetAsset",

  components: {
    MainLayout,
    vSelect,
  },
  mounted() {
    this.getQuestions();
  },
  props: {
    id: String,
  },
  data() {
    return {
      isLoading: true,
      questions: [],
      flexParams: [],
      suppliers: [],
      categories: [],
      offer: {
        project: "",
        comment: "",
        lines: [],
      },
    };
  },

  methods: {
    addLineToOffer() {
      let priceAdjust = {};
      this.questions.forEach((question) => {
        priceAdjust[question.id] = question.lines[0];
      });
      this.offer.lines.push({
        levkod: "",
        article: "",
        text: "",
        quantity: 1,
        price: "",
        discount: 0,
        category: "",
        subcategory: "",
        priceAdjust: priceAdjust,
      });
    },

    getQuestions: function () {
      this.isLoading = true;
      RentalAdministration.getOfferQuestions()
        .then((response) => {
          this.questions = response.data;
          this.addLineToOffer();
        })
        .catch(() => {});

      RentalAdministration.getOfferFlexParameters()
        .then((response) => {
          this.flexParams = response.data;
        })
        .catch(() => {});

      RentalAdministration.getOfferSuppliers()
        .then((response) => {
          this.suppliers = response.data;
        })
        .catch(() => {});

      RentalAdministration.getOfferCategories()
        .then((response) => {
          this.categories = response.data;
        })
        .catch(() => {});
    },
  },
};
</script>
