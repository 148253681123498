<template>
  <main-layout>
    <div class="page-header">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-6">
            <div class="pt-3 pb-3">
              <h5 class="mb-0">{{ $t("offers") }}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page-header-placeholder"></div>
    <div class="container">
      <div>
        <table class="table">
          <thead>
            <tr>
              <th>{{ $t("offer") }}</th>
              <th>{{ $t("customerNumber") }}.</th>
              <th>{{ $t("customer") }}</th>
              <th>{{ $t("created") }}</th>
              <th>{{ $t("reference") }}</th>
              <th>{{ $t("contact") }}</th>
              <th>{{ $t("amount") }}</th>

              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="customer in offers" :key="customer.ordernr">
              <td>{{ customer.ordernr }}</td>
              <td>{{ customer.kundnr }}</td>
              <td>{{ customer.kundnamn }}</td>
              <td>{{ customer.orderdatum.substr(0, 10) }}</td>
              <td>{{ customer.referensnamn }}</td>
              <td>{{ customer.kontaktnamn }}</td>
              <td v-if="customer.tot_pris">
                {{
                  parseFloat(customer.tot_pris).toLocaleString("sv-SE", {
                    style: "currency",
                    currency: customer.valuta,
                  })
                }}
              </td>
              <td v-if="!customer.tot_pris">
                {{ customer.valuta }}
                0
              </td>
              <td class="text-end pe-0">
                <router-link
                  class="btn btn-sm btn-outline-dark"
                  :to="{
                    name: 'offers.edit',
                    params: { id: customer.ordernr },
                  }"
                  >{{ $t("select") }}
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </main-layout>
</template>

<script>
import RentalAdministration from "@/services/api/rentalAdministration";
import MainLayout from "@/layouts/MainLayout";

export default {
  name: "ViewFixetAsset",

  components: {
    MainLayout,
  },
  mounted() {
    this.getCustomers();
  },
  props: {
    id: String,
  },
  data() {
    return {
      isLoading: true,
      offers: [],
    };
  },

  methods: {
    getCustomers: function () {
      this.isLoading = true;
      RentalAdministration.getOffers()
        .then((response) => {
          this.offers = response.data;
        })
        .catch(() => {});
    },
  },
};
</script>
